<template>
  <v-dialog :value="value" @input="close" max-width="600px">
    <v-card>

      <!-- Title -->
      <v-card-title>
        <h3>{{ $t('accounts.tabs.settings.mainDialog.title') }}</h3>
      </v-card-title>

      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row no-gutters>

            <!-- Color Hex -->
            <v-col cols="12" class="my-2">
              <v-text-field
                outlined
                :label="$t('accounts.tabs.settings.labels.color')"
                maxlength="100"
                hide-details
                v-model="form.color" />
            </v-col>

            <!-- Website -->
            <v-col cols="12" class="my-2">
              <v-text-field
                outlined
                :label="$t('accounts.tabs.settings.labels.website')"
                hide-details
                v-model="form.website" />
            </v-col>

            <!-- MyProductImpact Redirection URL -->
            <v-col cols="12" class="my-2">
              <v-text-field
                outlined
                :label="$t('accounts.tabs.settings.labels.myproductimpactRedirectionUrl')"
                hide-details
                v-model="form.myproductimpact_redirection_url" />
            </v-col>

            <!-- MyProductImpact Path -->
            <v-col cols="12" class="my-2">
              <v-text-field
                outlined
                :label="$t('accounts.tabs.settings.labels.myproductimpactPath')"
                maxlength="100"
                hide-details
                v-model="form.myproductimpact_path" />
            </v-col>

            <v-col cols="12">
              <AlertBar/>
            </v-col>

          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <ButtonBarFormDialog
            class="mx-3"
            :is-loading-btn-save="isLoadingBtn.btnSave"
            @submit="customSubmit"
            @cancel="close"/>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import dialogMixin   from "@/mixins/dialog"
import formRules     from "@/mixins/formRules";

export default {
  name: "AccountSettingsMainDialog",

  mixins: [dialogMixin, formRules],

  components: {
    AlertBar: () => import("@/components/Common/Bars/AlertBar"),
    ButtonBarFormDialog: () => import("@/components/Common/Buttons/ButtonBarFormDialog"),
  },

  props: {
    account: { type: Object, default: () => null }
  },

  data() {
    return {
      isLoadingBtn: {btnSave: false},

      defaultForm: {
        color: "",
        website: "",
        myproductimpact_redirection_url: "",
        myproductimpact_path: ""
      }
    }
  },

  methods: {
    customSubmit() {
      if (this.$refs.form.validate()) {
        this.isLoadingBtn.btnSave = true;

        this.$http
          .put(`/accounts/${this.account.id}/settings`, this.form, {
            headers: {Authorization: "Bearer " + this.$session.get('jwt')}
          })
          .then(res => {
            this.$store.commit("toast/showSuccess", this.$t('accounts.tabs.settings.mainDialog.success'));
            this.close(true);
          })
          .catch(err => {
            this.$store.commit("alert/showErrorAxios", err);
          })
          .finally(() => {
            this.isLoadingBtn.btnSave = false;
          });
      }
    },

  }
}
</script>

<style scoped>

</style>
